<template>
  <div>
    <el-dialog
      :visible="dialogVisible"
      width="60%"
      :title="title"
      :close-on-click-modal="true"
      @close="close"
    >
      <div class="bus">
        <div class="box" :style="{backgroundColor:item.color}" v-for="item in promTem" :key="item.id" @click="changeTemplate(item)">
          <p>{{ item.name }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TemplateVue",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "请选择模板",
      promTem: [
        { name: "十六强模板", component: "sixteenTem", color: "#fcf8ff" },
        { name: "冠军争夺模板", component: "championTem", color: "#ffccff" },
        { name: "八强模板", component: "eightTem", color: "#9999ff" },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("update:dialogVisible", false);
    },
    changeTemplate(item){
        this.$emit('changeName',item.component);
        this.close();
    }
  },
};
</script>

<style scoped lang="less">
.bus {
  display: flex;
  align-items: center;
  gap:20px;
  justify-content: center;
}
.box {
  border: 1px solid #e7e7e7;
  height: 100px;
  width:30%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
</style>
